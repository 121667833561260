import React from 'react'
import moment from 'moment';
import Clock from 'react-live-clock';
import SailRotationDiagram from '../../components/sailRotationDiagram/sailRotationDiagram';
import './LiveSailRotationSensorPanel.css';

class LiveSailRotationSensorPanel extends React.Component {

    getBrakeIndicationColour(brakeOn) {

        if (brakeOn) {
            return ("#00b000");
        }
        else {
            return ("#d69a00");
        }
    }

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span></span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var brakeIndicationColor = this.getBrakeIndicationColour(this.props.molen.latestSailRotationReading.brakeOn);

        return (

            <div className="panel-narrow">
                <div className="panel-header">
                    <div className="panel-top-left">
                        LIVE SAIL ROTATION
                    </div>
                    <div className="panel-top-right">
                        <Clock format={'HH:mm:ss'}
                            ticking={true}
                        />
                    </div>
                </div>
                <div className="panel-body">
                    <div className="sail-rotation-diagram-panel">
                        <SailRotationDiagram molen={this.props.molen} />
                        <div className="sail-rpm-panel">
                            <div className="app-panel-header-text"><br /><br /></div>
                            <div className="app-oversize-text">{Math.round((this.props.molen.latestSailRotationReading.currentSpeedRpm) * 10) / 10}</div>rpm<br />
                            <div className="app-supersmalltext"><br />
                            </div>
                            <div className="app-standard-text">
                                <b>{Math.round((this.props.molen.latestSailRotationReading.sailSpanMetres * 3.1416 * 2.23694 * this.props.molen.latestSailRotationReading.currentSpeedRpm) / 60)} mph</b><br />
                                sail tip speed
                            </div>
                        </div>

                    </div>

                    {this.props.molen.latestSailRotationReading.brakeLeverSensorFitted &&
                        <>
                            <div className="brake-status-panel" style={{ backgroundColor: brakeIndicationColor }}>
                                {this.props.molen.latestSailRotationReading.brakeOn ? <>BRAKE ON</> : <span class="blink">BRAKE OFF</span>}
                            </div>
                        </>
                    }

                    <div className="app-supersmalltext"><br />
                    </div>
                    </div>
                    

                    <div className="panel-header">
                        <div className="panel-top-left">
                            TOTAL SAIL REVOLUTIONS
                        </div>
                    </div>

                    <div className="panel-body">
                        <div className="sail-turns-panel-contents">
                            <div>
                                <table id="sail-turns-table">
                                    <tbody>
                                        <tr>
                                            <td width="38%" align="right"><div className="sail-table-large-numbers">{this.props.molen.latestSailRotationReading.revCountToday.toLocaleString('en-US')}</div></td>
                                            <td><div className="sail-table-major-text">today</div></td>
                                        </tr>
                                        <tr>
                                            <td align="right"><div className="sail-table-large-numbers" align="center">{this.props.molen.latestSailRotationReading.revCountThisYear.toLocaleString('en-US')}</div></td>
                                            <td><div className="sail-table-major-text">so far in {moment(this.props.molen.latestSailRotationReading.utcTime).format("YYYY")}</div></td>
                                        </tr>
                                        <tr>
                                            <td align="right"><div className="sail-table-large-numbers" align="center">{this.props.molen.latestSailRotationReading.totalRevCount.toLocaleString('en-US')}</div></td>
                                            <td><div className="sail-table-major-text">since {this.props.molen.latestSailRotationReading.revCountSinceDesc}</div></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <div className="app-supersmalltext"><br />
                            Reported {moment(this.props.molen.latestSailRotationReading.utcTime).format("HH:mm:ss")} by sail speed sensor
                        </div>


                    </div>
                </div>
        )
    }
}

export default LiveSailRotationSensorPanel
