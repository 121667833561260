import React from 'react';
import './headerBar.css';

class HeaderBar extends React.Component {

    componentDidMount() {
    }

    render() {

        var molen = this.props.molen;

        return (
            <>

                <div className="header-container">
                    <a href="/"><img src="/img/smartmolen_master2_256px.png" className="header-icon" alt=""></img></a>
                    <div className="header-title">
                        <div className="app-header-text">{molen.name}</div>
                        <div className="app-smalltext">{molen.county}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {(Math.round(Math.abs(molen.location.latitude) * 100)) / 100}&deg;{molen.location.latitude > 0 ? "N" : "S"}
    &nbsp;&nbsp;&nbsp;
    {(Math.round(Math.abs(molen.location.longitude) * 100)) / 100}&deg;{molen.location.longitude > 0 ? "E" : "W"}

                        </div>
                    </div>
                </div>

            </>
        )
    }
}



export default HeaderBar