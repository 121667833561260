import React from 'react';
import { Link, Switch, Route, matchPath } from 'react-router-dom';
import OrientationSensorDetails from '../../components/orientationSensorDetails/orientationSensorDetails'
import SailBrakeFanSensorDetails from '../../components/sailBrakeFanSensorDetails/sailBrakeFanSensorDetails';


class TechSensorTab extends React.Component {

  topNavClass = "topnav";

  onBurgerClick() {
    if (this.topNavClass === "topnav") {
      this.topNavClass = "topnav responsive";
    } else {
      this.topNavClass = "topnav";
    }
  }


  render() {

    var pathPrefix = '/';
    if (window.location.pathname.startsWith('/live/')) { pathPrefix = "/live/"; }
    if (window.location.pathname.startsWith('/tech/')) { pathPrefix = "/tech/"; }

    var orientationSensor1Link = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor', exact: true, strict: true }) != null ? "active" : "link";
    var orientationSensor2Link = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor/magnetometer2', }) != null ? "active" : "link";
    var sailBrakeFanSensorLink = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor/sailBrakefan', }) != null ? "active" : "link";

    return (
      <div width="100%">
        <div className={this.topNavClass}>
          {(this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 1) && <Link to={pathPrefix + this.props.molen.shortName + '/sensor'} className={orientationSensor1Link}>Magnetometer 1</Link>}
          {(this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2) && <Link to={pathPrefix + this.props.molen.shortName + '/sensor/magnetometer2'} className={orientationSensor2Link}>Magnetometer 2</Link>}
          {(this.props.molen.latestSailRotationReading != null) && <Link to={pathPrefix + this.props.molen.shortName + '/sensor/sailBrakefan'} className={sailBrakeFanSensorLink}>Sail/Brake/Fan</Link>}
          <a href="# " className="icon" onClick={ev => { this.onBurgerClick(); }}>
            <i className="fa fa-bars"></i>
          </a>
        </div>
        <div className="tabs">
          <Switch>
            <Route path={'/tech/:molenName/sensor/magnetometer2'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={2} />)} />
            <Route path={'/live/:molenName/sensor/magnetometer2'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={2} />)} />
            <Route path={'/:molenName/sensor/magnetometer2'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 2) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={2} />)} />

            <Route path={'/tech/:molenName/sensor/sailBrakefan'} render={(props) => (<SailBrakeFanSensorDetails molen={this.props.molen} />)} />
            <Route path={'/live/:molenName/sensor/sailBrakefan'} render={(props) => (<SailBrakeFanSensorDetails molen={this.props.molen} />)} />
            <Route path={'/:molenName/sensor/sailBrakefan'} render={(props) => (<SailBrakeFanSensorDetails molen={this.props.molen} />)} />

            <Route path={'/tech/:molenName/sensor'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 1) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={1} />)} />
            <Route path={'/live/:molenName/sensor'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 1) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={1} />)} />
            <Route path={'/:molenName/sensor'} render={(props) => ((this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length >= 1) && <OrientationSensorDetails molen={this.props.molen} sensorFriendlyId={1} />)} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default TechSensorTab