import React from 'react';
import CustomOrientationDiagramUpminster from './custom_upminster';
import CustomOrientationDiagramJill from './custom_jill';
import CustomOrientationDiagramPolkeys from './custom_polkeys';
import CustomOrientationDiagramWilton from './custom_wilton';
import CustomOrientationDiagramDrapers from './custom_drapers';
import GenericOrientationDiagramTower from './generic_tower';
import CustomOrientationDiagramLaceyGreen from './custom_laceygreen';
import CustomOrientationDiagramFulwell from './custom_fulwell';
import CustomOrientationDiagramAythorpe from './custom_aythorpe';
import CustomOrientationDiagramHardley from './custom_hardley';
import CustomOrientationDiagramLabbus from './custom_labbus';
import CustomOrientationDiagramCranbrook from './custom_cranbrook';
import CustomOrientationDiagramHolgate from './custom_holgate';
import CustomOrientationDiagramBremen from './custom_bremen';
import CustomOrientationDiagramLuebberstedt from './custom_luebberstedt';
import CustomOrientationDiagramPaula from './custom_paula';

class OrientationDiagram extends React.Component {

    componentDidMount() {
    }

    render() {

        var windDirectionIndicationColour;
        var windDirectionArrowOpacity;
        var windDirectionArrowRotation;

        if (this.props.windDirection == null)
        {
            windDirectionIndicationColour = "#FFFFFF";
            windDirectionArrowOpacity = 0;
            windDirectionArrowRotation = 0;
        }
        else
        {
            windDirectionIndicationColour = this.props.windIndicationColour;
            windDirectionArrowOpacity = 100;
            windDirectionArrowRotation = this.props.windDirection;
        }

        return (
            <>

                <div>
                    <svg height="288" width="288">

                        {/* --- outer compass graphic --- */}
                        <g name="compassBaseLayer" fontFamily="Open Sans" fontSize="24" fontWeight="bold">
                            <circle cx="144" cy="144" r="108" stroke="#b0b0b0" strokeWidth="3" fillOpacity="0" />

                            <g name="n-marker">
                                <polygon points="133,22 155,22 155,50 133,50" fill="#0f4c81" />
                                <text x="144" y="43" fill="white" textAnchor="middle" fontSize="18">N</text>
                            </g>

                            <g name="e-marker" transform="rotate(90, 144, 144)">
                                <polygon points="133,22 155,22 155,50 133,50" fill="#0f4c81" />
                                <text x="144" y="43" fill="white" textAnchor="middle" fontSize="18">E</text>
                            </g>

                            <g name="s-marker" transform="rotate(180, 144, 144)">
                                <polygon points="133,22 155,22 155,50 133,50" fill="#0f4c81" />
                                <text x="144" y="43" fill="white" textAnchor="middle" fontSize="18">S</text>
                            </g>

                            <g name="w-marker" transform="rotate(270, 144, 144)">
                                <polygon points="133,22 155,22 155,50 133,50" fill="#0f4c81" />
                                <text x="144" y="43" fill="white" textAnchor="middle" fontSize="18">W</text>
                            </g>
                        </g>

                        {/* --- camera angle graphic --- */}
                        <g name="cameraAngleLayer" transform={"rotate(" + this.props.webcamAngle + ", 144, 144)"} hidden={this.props.webcamAngle === -1}>
                            <polygon points="137,0 151,0 151,14 148,14 151,22 137,22 140,14 137,14" fill="#d0d0d0" />
                        </g>


                        {/* --- mill graphic --- */}
                        {this.props.graphicsSettings.displayGraphic === "tower" && <GenericOrientationDiagramTower molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.graphicsSettings.displayGraphic === "post" && <CustomOrientationDiagramAythorpe molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.graphicsSettings.displayGraphic === "smock" && <CustomOrientationDiagramLaceyGreen molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}

                        {this.props.molenShortName === "aythorpe" && <CustomOrientationDiagramAythorpe molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "drapers" && <CustomOrientationDiagramDrapers molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "fulwell" && <CustomOrientationDiagramFulwell molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "hardley" && <CustomOrientationDiagramHardley molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "jill" && <CustomOrientationDiagramJill molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "laceygreen" && <CustomOrientationDiagramLaceyGreen molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "polkeys" && <CustomOrientationDiagramPolkeys molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "upminster" && <CustomOrientationDiagramUpminster molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "wilton" && <CustomOrientationDiagramWilton molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "labbus" && <CustomOrientationDiagramLabbus molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "cranbrook" && <CustomOrientationDiagramCranbrook molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "holgate" && <CustomOrientationDiagramHolgate molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "bremen" && <CustomOrientationDiagramBremen molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "luebberstedt" && <CustomOrientationDiagramLuebberstedt molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}
                        {this.props.molenShortName === "paula" && <CustomOrientationDiagramPaula molenOrientation={this.props.molenOrientation} graphicsSettings={this.props.graphicsSettings} />}

                        {/* --- wind direction arrow --- */}
                        <g name="windLayer" transform={"rotate(" + windDirectionArrowRotation + ", 144, 144)"} fillOpacity={windDirectionArrowOpacity} strokeWidth="0">
                            <polygon points="130,4 144,14 162,4 144,50" fill={windDirectionIndicationColour} />
                        </g>

                    </svg>
                </div>

            </>
        )
    }
}



export default OrientationDiagram