import React from 'react';
import './molenListItem.css';
import WindDirectionArrow from '../../weatherForecastTable/windDirectionArrow';
import CompassIcon from '../../sensorIcons/compassIcon';
import CameraIcon from '../../sensorIcons/cameraIcon';
import SailSpeedSensorIcon from '../../sensorIcons/sailSpeedSensorIcon';
import FantailIcon from '../../sensorIcons/fantailIcon';
import WindLogic from '../../windLogic';
import moment from 'moment';

class MolenListItem extends React.Component {

    signalMolenSelected = (e) => {
        this.props.onMolenSelected(this.props.molen);
    };

    render() {

        var molen = this.props.molen;
        var noWind = (molen.latestWeatherObservation != null && molen.latestWeatherObservation.windDirection == null);

        var fantailText = "";
        if (molen.latestFantailSensorReading != null) {
            if (molen.latestFantailSensorReading.currentSpeedRpm > 0) { fantailText = "clockwise drive"; }
            if (molen.latestFantailSensorReading.currentSpeedRpm < 0) { fantailText = "anti-clockwise drive"; }
        }

        var openAlertsText = "";
        molen.openAlerts.forEach(alert => openAlertsText += alert.description + "\n");

        var windWarningTriangleIcon = "";
        var windWarningTriangleCaption = "";
        var windWarningSeverity = 0;
        var windWarningDirectionText = "";
        var windWarningTimeText = "";
        var windWarningMicroText = "";
        var windWarningSummaryText = "";

        if (molen.windWarnings.length > 0) {
            windWarningSeverity = Math.max(molen.windWarnings[0].strongWindWarningLevel, molen.windWarnings[0].tailWindWarningLevel);
            if (windWarningSeverity >= 1) { windWarningTriangleIcon = "img/wind_warning_yellow_200px.png"; windWarningTriangleCaption = "Wind Warning Triangle - Red"; }
            if (windWarningSeverity >= 2) { windWarningTriangleIcon = "img/wind_warning_orange_200px.png"; windWarningTriangleCaption = "Wind Warning Triangle - Orange"; }
            if (windWarningSeverity >= 3) { windWarningTriangleIcon = "img/wind_warning_red_200px.png"; windWarningTriangleCaption = "Wind Warning Triangle - Yellow"; }

            windWarningDirectionText = molen.windWarnings[0].windDirectionEightPointCompassLow;
            if (molen.windWarnings[0].windDirectionEightPointCompassLow !== molen.windWarnings[0].windDirectionEightPointCompassHigh) {
                windWarningDirectionText = molen.windWarnings[0].windDirectionEightPointCompassLow + " to " + molen.windWarnings[0].windDirectionEightPointCompassHigh;
            }

            if (this.props.molen.windWarnings[0].currentOrForecast === "CURRENT") {
                windWarningTimeText = (this.props.molen.windWarnings[0].endTimeUtc != null) ? "Until " + moment(this.props.molen.windWarnings[0].endTimeUtc).format("ddd HHmm") : "Until further notice";
                windWarningMicroText = (this.props.molen.windWarnings[0].tailWindWarningLevel > 0) ? "TAIL WIND" : "STRONG WIND";
                windWarningSummaryText = (this.props.molen.windWarnings[0].tailWindWarningLevel > 0) ? "TAIL WIND" : "STRONG WIND";
            }
            else {
                windWarningTimeText = (this.props.molen.windWarnings[0].endTimeUtc != null) ? moment(this.props.molen.windWarnings[0].startTimeUtc).format("ddd HHmm") + " - " + moment(this.props.molen.windWarnings[0].endTimeUtc).format("ddd HHmm") : "From " + moment(this.props.molen.windWarnings[0].startTimeUtc).format("ddd HHmm");
                windWarningMicroText = moment(this.props.molen.windWarnings[0].startTimeUtc).format("ddd HHmm");
                windWarningSummaryText = (this.props.molen.windWarnings[0].tailWindWarningLevel > 0) ? "FORECAST TAIL WIND" : "FORECAST STRONG WIND";
            }
        }

        //var hasSailSensor = molen.latestSailRotationReading !== null;
        //var hasFanSensor = molen.latestFantailSensorReading !== null
        var hasOpenWindWarnings = molen.windWarnings.length > 0;
        var col5DisplayMode = hasOpenWindWarnings ? "wind_warnings" : "blank";

        // logic to cycle between sail and fan sensor as well as wind warnings is commented out as it's buggy at small screen sizes and makes the page jump around
        // TODO: reinstate when fixed
        /*
        if (hasSailSensor && !hasFanSensor) { col5DisplayMode = "sail"; }
        if (!hasSailSensor && hasFanSensor) { col5DisplayMode = "fantail"; }
        if (hasSailSensor && hasFanSensor) { col5DisplayMode = this.props.displaySequence < 2 ? "sail" : "fantail"; }
        if (hasOpenWindWarnings && (this.props.displaySequence === 0 || this.props.displaySequence === 2)) { col5DisplayMode = "wind_warnings" }
        */

        return (
            <>
                <div className="molen-list-item-panel" onClick={this.signalMolenSelected} style={{"backgroundColor": this.props.selected ? "#D0D0D0": "#E6E6E6"}}>
                    <div className="molen-list-item-data-row" onClick={this.signalMolenSelected}>
                        <div className="molen-list-item-col-1-molen">
                            <div className="molen-list-item-title">
                                {molen.name}
                                {molen.openAlerts.length > 0 && <>&nbsp;&nbsp;<img src="img/warning_triangle.png" height="14" style={{ "verticalAlign": "middle" }} title={openAlertsText} alt="Warning Triangle"></img></>}
                            </div>
                            <div className="molen-list-item-lat-long">
                                {molen.county}<br />
                                {(Math.round(Math.abs(molen.location.latitude) * 100)) / 100}&deg;{molen.location.latitude > 0 ? "N" : "S"}
                                &nbsp;&nbsp;&nbsp;
                                {(Math.round(Math.abs(molen.location.longitude) * 100)) / 100}&deg;{molen.location.longitude > 0 ? "E" : "W"}
                            </div>
                            <div className="molen-list-item-col-1-sensor-icons">
                                {(molen.webcam != null) && <div className="molen-list-item-sensor-icon"><CameraIcon size="18" /></div>}
                                {(molen.latestOrientationSensorReading != null) && <div className="molen-list-item-sensor-icon"><CompassIcon size="18" /></div>}
                                {(molen.latestSailRotationReading != null) && <div className="molen-list-item-sensor-icon"><SailSpeedSensorIcon size="18" /></div>}
                                {(molen.latestFantailSensorReading != null) && <div className="molen-list-item-sensor-icon"><FantailIcon size="18" /></div>}
                            </div>
                        </div>

                        <div className="molen-list-item-col-2-sensors">
                            <div className="molen-list-item-sensor-icons">
                                {(molen.webcam != null) && <><div className="molen-list-item-webcam-tag">WEBCAM</div></>}
                            </div>
                            <div className="molen-list-item-sensor-icons">
                                {(molen.webcam != null) && <><div className="molen-list-item-sensor-icon"><CameraIcon size="18" /></div></>}
                                {(molen.latestOrientationSensorReading != null) && <div className="molen-list-item-sensor-icon"><CompassIcon size="18" /></div>}
                                {(molen.latestSailRotationReading != null) && <div className="molen-list-item-sensor-icon"><SailSpeedSensorIcon size="18" /></div>}
                                {(molen.latestFantailSensorReading != null) && <div className="molen-list-item-sensor-icon"><FantailIcon size="18" /></div>}
                            </div>
                            <div className="molen-list-item-subtitle"><a href={"/" + molen.shortName}>Dashboard</a></div>
                        </div>

                        <div className="molen-list-item-col-3-cap">
                            {molen.latestOrientationSensorReading &&
                                <>
                                    Sails Facing<br />
                                    {(molen.isCapInFixedPosition && molen.windingRAGStatus !== "FAULT") && <>
                                        <div className="molen-list-item-headline-stat">{molen.latestOrientationSensorReading.compassPoint}</div>
                                        <div className="molen-list-item-subtitle-red">Not turning to wind</div>
                                    </>}
                                    {(molen.isCapInFixedPosition && molen.windingRAGStatus === "FAULT") && <>
                                        <div className="molen-list-item-headline-stat">-</div>
                                        <div className="molen-list-item-subtitle-red">Not turning to wind</div>
                                    </>}
                                    {((!molen.isCapInFixedPosition) && (molen.windingRAGStatus !== "FAULT" && molen.windingRAGStatus !== "GREY")) && <>
                                        <div className="molen-list-item-headline-stat">{molen.latestOrientationSensorReading.compassPoint}</div>
                                        <div className="molen-list-item-subtitle">{molen.latestOrientationSensorReading.bearing}&deg;</div>
                                    </>}
                                    {((!molen.isCapInFixedPosition) && molen.windingRAGStatus === "FAULT") && <>
                                        <div className="molen-list-item-headline-stat">-</div>
                                        <div className="molen-list-item-subtitle">No signal</div>
                                    </>}
                                    {((!molen.isCapInFixedPosition) && molen.windingRAGStatus === "GREY") && <>
                                        <div className="molen-list-item-headline-stat">{molen.latestOrientationSensorReading.compassPoint}</div>
                                        <div className="molen-list-item-subtitle">Accuracy warning</div>
                                    </>}
                                </>
                            }
                        </div>
                        <div className="molen-list-item-col-4-wind">
                            {molen.latestWeatherObservation && <>
                                <div className="molen-list-wind">
                                    <div className="molen-list-wind-left">
                                        Wind<br />
                                        <div className="molen-list-item-headline-stat">{(noWind) ? "None" : this.props.molen.latestWeatherObservation.windDirectionCompassPoint}</div>
                                        {new WindLogic().isWindGusting(molen.latestWeatherObservation.windSpeedMs, molen.latestWeatherObservation.windGustMs) ?
                                            <div className="molen-list-item-subtitle-color-reverse">{Math.round(molen.latestWeatherObservation.windGustMs * 2.24)} mph</div> :
                                            <div className="molen-list-item-subtitle">{Math.round(molen.latestWeatherObservation.windSpeedMs * 2.24)} mph</div>}

                                    </div>
                                </div>
                            </>
                            }
                        </div>
                        <div className="molen-list-item-col-4a-wind-arrow">
                            {molen.latestWeatherObservation && <>
                                <div className="molen-list-wind-right">
                                    {(!noWind) && <WindDirectionArrow windingRAGStatus={molen.windingRAGStatus} windDirection={molen.latestWeatherObservation.windDirection} molenOrientation={molen.latestOrientationSensorReading != null ? molen.latestOrientationSensorReading.bearing : null} windSpeedMs={molen.latestWeatherObservation.windSpeedMs} size="42" palette="dark" />}
                                </div>
                            </>
                            }
                        </div>
                        <div className="molen-list-item-col-4a-wind-arrow-small">
                            {molen.latestWeatherObservation && <>
                                <div className="molen-list-wind-right">
                                    {(!noWind) && <WindDirectionArrow windingRAGStatus={molen.windingRAGStatus} windDirection={molen.latestWeatherObservation.windDirection} molenOrientation={molen.latestOrientationSensorReading != null ? molen.latestOrientationSensorReading.bearing : null} windSpeedMs={molen.latestWeatherObservation.windSpeedMs} size="32" palette="dark" />}
                                </div>
                            </>
                            }
                        </div>
                        <div className="molen-list-item-col-5-multi">

                            {col5DisplayMode === "sail" && <>
                                <>
                                    <div className="molen-list-item-col-5-sails">
                                        Sail Speed<br />
                                        <div className="molen-list-item-headline-stat">{Math.round((this.props.molen.latestSailRotationReading.currentSpeedRpm) * 10) / 10} rpm</div>
                                        <div className="molen-list-item-subtitle">{molen.latestSailRotationReading.brakeLeverSensorFitted ? (molen.latestSailRotationReading.brakeOn ? "Brake On" : "Brake Off") : <>&nbsp;</>}</div>
                                    </div>
                                </>
                            </>}

                            {col5DisplayMode === "fantail" &&
                                <>
                                    <div className="molen-list-item-col-5-fantail">
                                        Fantail<br />
                                        <div className="molen-list-item-headline-stat">{Math.round((Math.abs(this.props.molen.latestFantailSensorReading.currentSpeedRpm)) * 10) / 10} rpm</div>
                                        <div className="molen-list-item-subtitle">{fantailText}&nbsp;</div>
                                    </div>
                                </>}

                            {col5DisplayMode === "wind_warnings" &&
                                <>
                                    <div className="molen-list-item-col-5-wind-warnings">
                                        <div className="molen-list-item-wind-warning-left">
                                            <img className="molen-list-item-wind-warning-icon" src={windWarningTriangleIcon} style={{ "verticalAlign": "top" }} title={"Wind Warning"} alt={windWarningTriangleCaption}></img>
                                            <div className="molen-list-item-wind-warning-micro-caption">{windWarningMicroText}</div>
                                        </div>
                                        <div className="molen-list-item-weather-warning-text">
                                            <b>{windWarningSummaryText}</b><br />
                                            {windWarningTimeText}<br />
                                            {new WindLogic().isWindGusting(this.props.molen.windWarnings[0].maxWindSpeedMs, this.props.molen.windWarnings[0].maxWindGustMs) ?
                                                <div className="molen-list-item-weather-warning-text-color-reverse">{Math.round(this.props.molen.windWarnings[0].maxWindGustMs * 2.237)} mph {windWarningDirectionText}</div> :
                                                <>{Math.round(this.props.molen.windWarnings[0].maxWindSpeedMs * 2.237)} mph {windWarningDirectionText}</>}
                                            <div className="molen-list-item-weather-warning-text-red">{molen.windWarnings != null & molen.windWarnings.length > 1 ? "+ further warnings" : ""}</div>
                                        </div>
                                    </div>
                                </>}

                        </div>
                    </div>
                    {(this.props.selected !== null && this.props.selected && this.props.molen.webcam) &&
                            <div className="molen-list-item-webcam-row">
                                {this.props.molen.webcam !== null &&
                                <iframe src={this.props.molen.webcam.url} allow="autoplay" className="molen-list-item-webcam-view" width="688" height="387" scrolling="no" title="millwebcam" allowFullScreen style={{ border: "none" }}></iframe>
                                }</div>
                        }

                </div>

            </>
        )
    }
}



export default MolenListItem